import React, { useContext, useState } from "react";
import { MdAutorenew } from "react-icons/md";
import { YoutubeContext, YoutubeDispatch } from "../context";
import axios from "axios";
import { MdCloudDownload } from "react-icons/md";

const DownloadOutput = () => {
    const AppOptionsHandler = useContext(YoutubeDispatch);
    const { start, url, dataUrl, duration, width, fps, format, videoDuration } = useContext(YoutubeContext);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState("No Video Loaded");
    const [showErr, setShowErr] = useState(false);

    const download = () => {
        const base_url =
            process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_Local_URL;
        if (videoDuration) {
            setLoading(true);
            AppOptionsHandler("dataUrl", null);
            setShowErr(false);
            axios
                .get(`${base_url}/api/download?URL=${url}`, {
                    headers: {
                        start: `00:${Math.floor(start / 60).toLocaleString(undefined, {
                            minimumIntegerDigits: 2,
                        })}:${(Math.floor((start % 60) * 10) / 10).toLocaleString(undefined, {
                            minimumIntegerDigits: 2,
                            minimumFractionDigits: 1,
                        })}`,
                        duration: duration,
                        fps: parseInt(fps),
                        format: format,
                        width: width,
                    },
                    responseType: "blob", // Tell Axios to expect a blob response
                })
                .then((res) => {
                    const videoBlob = res.data;
                    const objectUrl = URL.createObjectURL(new Blob([videoBlob]));

                    AppOptionsHandler("dataUrl", objectUrl);
                    setLoading(false);
                })
                .catch((err) => {
                    setErr("Something is Wrong! try again");
                    setShowErr(true);
                    setLoading(false);
                });
        } else {
            setErr("No Video Loaded");
            setShowErr(true);
        }
    };

    return (
        <div className="relative pt-4 w-full flex flex-col gap-4 justify-center">
            <button
                className={`rounded-full bg-hl2 text-white text-xl px-12 py-3 self-center flex gap-1 items-center ${
                    loading && "opacity-70"
                }`}
                onClick={download}
                disabled={loading}
            >
                Convert
                <MdAutorenew className={`mt-1 ${loading && "animate-spin"}`} />
            </button>
            {showErr ? (
                <span className="absolute text-center top-[68px] left-1/2 -translate-x-1/2 text-sm text-red-400">
                    {err}
                </span>
            ) : null}
            <div className="w-fll flex flex-col gap-2 items-center">
                {dataUrl ? (
                    format === "gif" ? (
                        <div className="py-4">
                            <img src={dataUrl} alt="output" width={width} />
                            <a
                                href={dataUrl}
                                download={"cliped-vid.gif"}
                                className="rounded-full bg-primary mt-4 flex text-white py-2 px-4 gap-2 items-center mx-auto w-fit"
                            >
                                Download
                                <MdCloudDownload className="h-5 w-5" />
                            </a>
                        </div>
                    ) : (
                        <div className="py-4">
                            <video controls>
                                <source src={dataUrl} type="video/webm" />
                            </video>
                            <a
                                href={dataUrl}
                                download={"cliped-vid.webm"}
                                className="rounded-full bg-primary mt-4 flex text-white py-2 px-4 gap-1 items-center mx-auto w-fit"
                            >
                                Download
                                <MdCloudDownload className="h-5 w-5" />
                            </a>
                        </div>
                    )
                ) : null}
            </div>
        </div>
    );
};

export default DownloadOutput;
