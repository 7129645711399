import React, { createContext, useContext, useState } from "react";

const YoutubeContext = createContext();
const YoutubeDispatch = createContext();

function ContextProvider({ children }) {
    const [appOptions, setAppOptions] = useState({
        start: 0,
        duration: 1,
        player: null,
        url: "",
        videoId: "",
        dataUrl: null,
        progressBar: 0,
        isPlaying: false,
        currentTime: 0,
        videoDuration: 0,
        isPreviewing: false,
        width: 500,
        format: "webm",
        fps: 16,
        linkErr: "Wrong url! https://www.youtube.com/watch?v=...",
        showErr: false,
    });

    const AppOptionsHandler = (state, value) => {
        setAppOptions((prev) => {
            return {
                ...prev,
                [state]: value,
            };
        });
    };

    return (
        <YoutubeContext.Provider value={appOptions}>
            <YoutubeDispatch.Provider value={AppOptionsHandler}>{children}</YoutubeDispatch.Provider>
        </YoutubeContext.Provider>
    );
}

export { ContextProvider, YoutubeContext, YoutubeDispatch };
