import React, { useContext, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { ContextProvider, YoutubeContext, YoutubeDispatch } from "../context";
const YoutubeLink = () => {
    // const [showErr, setShowErr] = useState(false);

    const AppOptionsHandler = useContext(YoutubeDispatch);
    const { url, linkErr, showErr } = useContext(YoutubeContext);
    const handleChange = (e) => {
        AppOptionsHandler("start", 0);
        AppOptionsHandler("duration", 0);
        AppOptionsHandler("url", e.target.value);
    };

    const handleLoad = () => {
        setVideoId(null);
        if (url.includes("youtube.com/watch?v=")) {
            const val = url.split("watch?v=")[1].slice(0, 11);
            setVideoId(val);
        } else {
            AppOptionsHandler("linkErr", "Wrong url! https://www.youtube.com/watch?v=...");
            AppOptionsHandler("showErr", true);
        }
    };

    const setVideoId = (val) => {
        AppOptionsHandler("videoId", val);
    };
    return (
        <div className="relative flex w-full  gap-4 py-8 max-w-[500px]">
            <input
                className=" py-2 px-4 border text-text rounded-full shadow-md flex-grow focus:outline-none"
                onChange={handleChange}
                placeholder="Video URL"
            />
            <button
                className="rounded-full bg-hl2 min-w-[42px] text-xl text-white flex justify-center items-center "
                onClick={handleLoad}
            >
                <AiOutlineArrowDown />
            </button>
            {showErr ? <span className="absolute top-2 left-4 text-sm text-red-400">{linkErr}</span> : null}
        </div>
    );
};

export default YoutubeLink;
