import React from "react";

function Header() {
  return (
    <div className="py-4 flex flex-col items-center">
      <img src="./tubegiffer.png" alt="logo" width={100} />
      <span className="text-sm text-text pt-2">
        Turn YouTube Moments into GIF Magic!
      </span>
      <span className="text-sm text-text ">Quick and Easy!</span>
    </div>
  );
}

export default Header;
