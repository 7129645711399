import { useContext, useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import { YoutubeContext, YoutubeDispatch } from "../context";
const YoutubePreview = () => {
    // context
    const AppOptionsHandler = useContext(YoutubeDispatch);
    const { start, duration, videoId, player, progressBar, isPlaying, currentTime, videoDuration, isPreviewing } =
        useContext(YoutubeContext);
    // ==========
    const pContainer = useRef(null);
    const redbar = useRef(null);
    const greyBar = useRef(null);
    const [playerWidth, setPlayerWidth] = useState(500);

    const onReady = (event) => {
        AppOptionsHandler("player", event.target);
        AppOptionsHandler("videoDuration", event.target.getDuration());
        setPlayerWidth(pContainer.current.clientWidth);
    };

    const opts = {
        height: "360",
        width: playerWidth,
        suggestedQuality: "medium",
        fs: false,
        playerVars: {
            autoplay: 0,
            loop: 1, // Set to 1 to enable video looping
            fs: 0,
            rel: 0,
            iv_load_policy: 0,
            showinfo: 0,
            modestbranding: 1,
        },
    };

    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(handleProgress, 100);
            console.log("running");
        }

        return () => {
            console.log("stoped");
            clearInterval(interval);
        };
    }, [isPlaying]);

    const handleStateChange = (event) => {
        handleProgress();
        if (event.data === 1) {
            AppOptionsHandler("isPlaying", true);
        } else if (event.data === 2) {
            AppOptionsHandler("isPreviewing", false);
            AppOptionsHandler("isPlaying", false);
        } else {
            AppOptionsHandler("isPlaying", false);
            checkVideoDuration();
        }
    };

    const checkVideoDuration = () => {
        if (player.getDuration() > 1860) {
            player.destroy();
            AppOptionsHandler("linkErr", "Only Videos less than 30 min");
            AppOptionsHandler("showErr", true);
        }
    };

    function handleProgress() {
        let time = Math.round(player.getCurrentTime() * 10) / 10;
        let percentage = (time * 100) / videoDuration;
        AppOptionsHandler("currentTime", time);
        AppOptionsHandler("progressBar", percentage);
        if (time > parseFloat(start) + parseInt(duration) && isPreviewing) {
            player.seekTo(parseFloat(start), true);
        }
    }

    const scrub = (e) => {
        const scrub = e.nativeEvent.offsetX / greyBar.current.offsetWidth;
        AppOptionsHandler("progressBar", scrub * 100);
        let time = Math.round(scrub * videoDuration * 10) / 10;
        AppOptionsHandler("currentTime", time);
        player.seekTo(scrub * videoDuration, true);
    };

    const youtubeScrub = () => {
        if (!isPlaying) {
            handleProgress();
        }
    };

    return (
        <div className="flex flex-col max-w-[500px] w-full">
            <div ref={pContainer} className="w-full bg-black rounded-xl flex justify-center overflow-hidden">
                <YouTube
                    className="min-h-[360px] m-auto"
                    onReady={onReady}
                    videoId={videoId}
                    opts={opts}
                    onStateChange={handleStateChange}
                />
            </div>
            <div className="flex flex-wrap flex-col-reverse sm:flex-row items-center justify-between mt-8">
                {/* preview timer */}
                <div className="flex">
                    <div className="flex  flex-nowrap text-primary ">
                        <span>
                            {Math.floor(currentTime / 60).toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                            })}
                        </span>
                        <span> : </span>
                        <span>
                            {(Math.floor((currentTime % 60) * 10) / 10).toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                                minimumFractionDigits: 1,
                            })}
                        </span>
                    </div>
                    <div className="flex flex-nowrap text-black pr-2">
                        <span className="text-black"> / </span>
                        <span>
                            {Math.floor(videoDuration / 60).toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                            })}
                        </span>
                        <span> : </span>
                        <span>
                            {(Math.floor((videoDuration % 60) * 10) / 10).toLocaleString(undefined, {
                                minimumIntegerDigits: 2,
                            })}{" "}
                        </span>
                    </div>
                </div>

                {/* preview bar */}
                <div
                    className="relative h-4 w-full sm:w-auto  bg-scd flex flex-grow rounded-full overflow-hidden max-w-[400px]"
                    ref={greyBar}
                    onClick={scrub}
                >
                    <div
                        ref={redbar}
                        style={{
                            backgroundColor: "#0172EA",
                            flexBasis: `${progressBar}%`,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default YoutubePreview;
