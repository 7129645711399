import YoutubePreview from "./components/YoutubePreview";
import Controls from "./components/Controls";

import Header from "./components/Header";
import YoutubeLink from "./components/YoutubeLink";
function App() {
    return (
        <div className="max-w-[600px] px-4 flex flex-col items-center m-auto pb-12 shadow-2xl bg-white">
            <Header />
            <YoutubeLink />
            <YoutubePreview />
            <Controls />
        </div>
    );
}

export default App;
