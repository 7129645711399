import React, { useContext } from "react";
import { BsFastForwardFill } from "react-icons/bs";
import { YoutubeContext, YoutubeDispatch } from "../context";
import { HiMiniPlayPause } from "react-icons/hi2";
import { BiSolidUpArrow } from "react-icons/bi";

const SkipTo = () => {
    const { currentTime, player, isPlaying } = useContext(YoutubeContext);
    const AppOptionsHandler = useContext(YoutubeDispatch);

    const goto = (e) => {
        player.seekTo(currentTime + parseInt(e.target.getAttribute("data-time")), true);
        player.playVideo();
    };

    const togglePlay = () => {
        if (isPlaying) {
            player.pauseVideo();
        } else player.playVideo();
    };

    const startHere = () => {
        player.pauseVideo();
        AppOptionsHandler("start", currentTime);
    };

    return (
        <div className="flex gap-2 flex-wrap justify-center">
            <button
                className="relative rounded-full bg-hl2 text-white text-base w-[100px] h-[48px] gap-1 items-center"
                onClick={startHere}
            >
                <BiSolidUpArrow className="absolute left-0 right-0 top-1 m-auto " />
                <span className="absolute left-0 right-0 top-4 m-auto ">Copy Time</span>
            </button>
            <div className="bg-white shadow-md border flex  flex-grow flex-wrap gap-2 rounded-3xl justify-center p-2">
                <button
                    onClick={goto}
                    data-time={-5}
                    className="bg-white text-lg text-primary border border-primary px-4 rounded-full flex items-center gap-1 hover:bg-primary hover:text-white "
                >
                    <BsFastForwardFill className="rotate-180 mt-0.5" />
                    {"5"}
                </button>
                <div className="flex gap-2 justify-center order-first w-full xs:w-auto xs:order-none">
                    <button
                        onClick={goto}
                        data-time={-1}
                        className="bg-white text-lg text-primary border border-primary px-4 rounded-full flex items-center gap-1 hover:bg-primary hover:text-white "
                    >
                        <BsFastForwardFill className="rotate-180 mt-0.5" />
                        {"1"}
                    </button>
                    <button
                        onClick={togglePlay}
                        data-time={1}
                        className="bg-white text-lg text-primary border border-primary px-4 rounded-full flex items-center gap-1 hover:bg-primary hover:text-white "
                    >
                        <HiMiniPlayPause />
                    </button>

                    <button
                        onClick={goto}
                        data-time={1}
                        className="bg-white text-lg text-primary border border-primary px-4 rounded-full flex items-center gap-1 hover:bg-primary hover:text-white "
                    >
                        {"1"}
                        <BsFastForwardFill className="mt-0.5" />
                    </button>
                </div>

                <button
                    onClick={goto}
                    data-time={5}
                    className="bg-white text-lg text-primary border border-primary px-4 rounded-full flex items-center gap-1 hover:bg-primary hover:text-white "
                >
                    {"5"}
                    <BsFastForwardFill className="mt-0.5" />
                </button>
            </div>
        </div>
    );
};

export default SkipTo;
