import React, { useContext, useRef, useState } from "react";
import { YoutubeContext, YoutubeDispatch } from "../context";
import { MdOutlineOndemandVideo, MdDisplaySettings } from "react-icons/md";
import SkipTo from "./SkipTo";
import DownloadOutput from "./DownloadOutput";
import FormatAndFps from "./Format&fps";
import ImageWidth from "./ImageWidth";
const Controls = () => {
    const AppOptionsHandler = useContext(YoutubeDispatch);
    const { player, currentTime, isPreviewing, start, duration } = useContext(YoutubeContext);
    const [counter, setCounter] = useState(currentTime);
    const refDuration = useRef(null);

    const handleStart = (e) => {
        AppOptionsHandler("start", e.target.value);
    };

    const handleDuration = (e) => {
        AppOptionsHandler("duration", e.target.value);
    };

    const handlePreview = () => {
        if (start && duration) {
            AppOptionsHandler("isPreviewing", true);
            player.seekTo(parseFloat(start), true);
            player.playVideo();
        }
    };

    return (
        <div className="flex w-full flex-col py-2 gap-4 max-w-[500px]">
            <SkipTo />
            <div className=" pt-4 gap-4 flex flex-col ">
                <div className="flex">
                    <label
                        htmlFor="start"
                        className="bg-scd  rounded-l-full text-right text-primary text-lg px-2 py-2 border border-scd w-[104px] whitespace-nowrap "
                    >
                        Start at:
                    </label>
                    <input
                        type="number"
                        name="start"
                        min={0}
                        step={0.1}
                        onChange={handleStart}
                        className=" rounded-r-full w-full text-primary text-lg px-4  border border-scd focus:outline-none"
                        value={start.toLocaleString(undefined, {
                            minimumIntegerDigits: 2,
                            minimumFractionDigits: 1,
                        })}
                    />
                </div>
                <div className="flex relative">
                    <label
                        htmlFor="duration"
                        className="bg-scd  rounded-l-full text-right text-primary text-lg px-2 py-2 border border-scd w-[104px]"
                    >
                        Duration:
                    </label>
                    <input
                        type="number"
                        ref={refDuration}
                        min={1}
                        max={10}
                        name="duration"
                        className=" rounded-r-full w-full flex-grow text-primary text-lg px-4  border border-scd focus:outline-none"
                        onChange={handleDuration}
                        value={duration.toLocaleString(undefined, {
                            minimumIntegerDigits: 2,
                        })}
                    />
                    <span className="absolute text-sm text-gray-300 top-11 left-4">max: 10 sec</span>
                </div>
            </div>
            <button
                className=" rounded-full bg-primary text-white text-xl px-12 py-3 self-center flex gap-1 items-center "
                onClick={handlePreview}
            >
                Preview
                <MdOutlineOndemandVideo className="mt-1" />
            </button>
            <div className="mt-8 text-text text-sm flex justify-center items-center gap-1">
                <span>Image Options</span>
                <MdDisplaySettings className="mt-0.5" />
            </div>
            <div className="bg-white shadow-md border flex flex-col gap-2 rounded-3xl items-center px-2 py-4">
                <FormatAndFps />
                <ImageWidth />
                <DownloadOutput />
            </div>
        </div>
    );
};

export default Controls;
