import React, { useContext, useState } from "react";
import { YoutubeContext, YoutubeDispatch } from "../context";

const FormatAndFps = () => {
  const AppOptionsHandler = useContext(YoutubeDispatch);
  const { format } = useContext(YoutubeContext);

  const handleChange = (e) => {
    AppOptionsHandler("format", e.target.value);
  };

  const handleSelect = (e) => {
    AppOptionsHandler("fps", e.target.value);
  };
  return (
    <div className="w-full flex flex-wrap justify-center items-center gap-8 mb-2 ">
      <div className="flex gap-4" onChange={handleChange}>
        <span className="text-text">Format :</span>
        <label htmlFor="format">
          <input
            type="radio"
            value="webm"
            name="format"
            checked={format === "webm"}
          />{" "}
          WEBM
        </label>
        <label htmlFor="format">
          <input
            type="radio"
            value="gif"
            name="format"
            checked={format === "gif"}
          />{" "}
          GIF
        </label>
      </div>
      <div>
        <label htmlFor="fps" className="text-text">
          FPS :{" "}
        </label>
        <select
          name="fps"
          className="border p-1 focus:outline-none rounded-lg"
          onChange={handleSelect}
        >
          <option value="18">18 FPS</option>
          <option value="16" selected>
            16 FPS
          </option>
          <option value="14">14 FPS</option>
          <option value="12">12 FPS</option>
          <option value="10">10 FPS</option>
          <option value="8">08 FPS</option>
          <option value="6">06 FPS</option>
        </select>
      </div>
    </div>
  );
};

export default FormatAndFps;
