import React, { useContext, useState } from "react";
import { MdOutlineOndemandVideo, MdSettingsEthernet } from "react-icons/md";
import { YoutubeDispatch } from "../context";
import { cn } from "../lib/utils";
const ImageWidth = () => {
  const AppOptionsHandler = useContext(YoutubeDispatch);
  const [width, setWidth] = useState("500");

  const handleWidth = (e) => {
    if (e.target.value) {
      setWidth((prev) => e.target.value);
      AppOptionsHandler("width", e.target.value);
    }
  };

  return (
    <>
      <div className="pb-2 text-text text-sm flex justify-center items-center gap-2">
        <span>Width</span>
        <MdSettingsEthernet className="mt-0.5" />
      </div>
      <div
        onClick={handleWidth}
        className="flex gap-2 flex-wrap justify-center "
      >
        <button
          value={500}
          className={cn(
            "rounded-full bg-scd text-primary px-8 py-2 self-center flex gap-1 items-center",
            width === "500" && "bg-primary text-white"
          )}
        >
          500
          <MdSettingsEthernet />
        </button>
        <button
          value={300}
          className={cn(
            "rounded-full bg-scd text-primary px-8 py-2 self-center flex gap-1 items-center",
            width === "300" && "bg-primary text-white"
          )}
        >
          300
          <MdSettingsEthernet />
        </button>
        <button
          value={200}
          className={cn(
            "rounded-full bg-scd text-primary px-8 py-2 self-center flex gap-1 items-center",
            width === "200" && "bg-primary text-white"
          )}
        >
          200
          <MdSettingsEthernet />
        </button>
        <button
          value={100}
          className={cn(
            "rounded-full bg-scd text-primary px-8 py-2 self-center flex gap-1 items-center",
            width === "100" && "bg-primary text-white"
          )}
        >
          100
          <MdSettingsEthernet />
        </button>
      </div>
    </>
  );
};

export default ImageWidth;
